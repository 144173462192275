import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import EntityInterface from 'common/types/entities/EntityInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { closePopup as closePopupAction } from 'publisher/actionsManagement'
import { usePage } from 'publisher/store'
import { getAscendantPopup } from 'publisher/store/page/pageSelectors'
import { setCloseCookie } from 'publisher/utils/cookie'

export function useClosePopup(
  entity: EntityInterface | OldEntityInterface,
  pageId: number,
) {
  const dispatch = useDispatch()
  const popup = usePage(p => getAscendantPopup(p, entity)) as
    | OldEntityInterface
    | undefined

  return useCallback(() => {
    if (popup) {
      setCloseCookie(pageId, popup.id, popup.options.closedDays)
      setTimeout(() => dispatch(closePopupAction), 1000)
    }
  }, [dispatch, pageId, popup])
}
