import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { redirectionTypes } from 'common/constants/buttonTypes'
import * as exceptions from 'common/errors'
import { usePage as useTypedPage } from 'publisher/store'
import { getSurveysResults } from 'publisher/store/optIn/optInSelectors'
import typedPageSelectors from 'publisher/store/page/pageSelectors'
import { blogPageTypes } from '../../common/constants/pageTypes'
import { optInFail, optInSuccess } from '../actions/optInActions'
import { optIn } from '../api/optInApi'
import { Query, QueryKeys } from '../components/core/MatchMedia'
import { validateEmptyOptInFields } from '../utils/fieldValidation'
import { redirectTo } from '../utils/redirect'
import useManagement, { selectors as managementSel } from './useManagement'
import useOptIn, { selectors as optInSelectors } from './useOptIn'
import usePage, { selectors as pageSelectors } from './usePage'
import { useRecaptchaValidation } from './useRecaptchaValidation'

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

function useOptInSubmit({ optInButtonEntity, closePopup }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const fields = useOptIn(optInSelectors.getCustomerOptInFields)
  const surveysResults = useOptIn(getSurveysResults)
  const dateTimeField = useOptIn(optInSelectors.getDateTimeField)
  const popup = usePage(p =>
    pageSelectors.getAscendantPopup(p, optInButtonEntity),
  )
  const popupId = popup ? popup.id : null
  const pageType = usePage(pageSelectors.getPageType)
  const isTemplate = usePage(pageSelectors.isTemplate)
  const isDesktop = useManagement(managementSel.isDesktop)
  const isPreview = useManagement(managementSel.isPreviewMode)
  const funnelStepId = useManagement(managementSel.getFunnelStepId)
  const confirmedCheckboxesIds = useManagement(
    managementSel.getConfirmedExplicitConsentsIds,
  )
  const visibleOptInFieldProperties = useTypedPage(p =>
    typedPageSelectors.getVisibleOptInFieldProperties(
      p,
      isDesktop,
      fields,
      popupId,
    ),
  )
  const mandatoryCheckboxes = useTypedPage(p =>
    typedPageSelectors.getVisibleMandatoryCheckboxes(p, isDesktop, popupId),
  )

  const visibleOptInFields = usePage(p =>
    pageSelectors.getVisibleOptInFields(
      p,
      isDesktop,
      popupId,
      fields,
      optInButtonEntity.id,
    ),
  )

  const { validateRecaptchas } = useRecaptchaValidation(optInButtonEntity)

  const submit = async e => {
    e.preventDefault()

    if (isTemplate || isPreview) {
      return
    }

    const checkboxesErrors = mandatoryCheckboxes.reduce((errors, checkbox) => {
      return confirmedCheckboxesIds.includes(checkbox.id)
        ? errors
        : [
            ...errors,
            'options' in checkbox
              ? checkbox.options.errorText
              : checkbox.errorText,
          ]
    }, [])

    if (checkboxesErrors.length > 0) {
      setErrors(checkboxesErrors)
      return
    }
    const emptyFieldErrors = validateEmptyOptInFields(
      visibleOptInFieldProperties,
      t,
    )
    // we need to leave blog pages because it may have inputs from layout or post layout
    if (
      Object.keys(emptyFieldErrors).length &&
      !blogPageTypes.includes(pageType)
    ) {
      const common = []
      dispatch(optInFail({ common, fields: emptyFieldErrors }))
      return
    }

    const { recaptchaToken, error } = validateRecaptchas()

    if (error) return

    setLoading(true)
    try {
      const { data } = await optIn(
        visibleOptInFields,
        dateTimeField,
        timeZone,
        popupId,
        window.matchMedia(Query[QueryKeys.DESKTOP]).matches,
        surveysResults,
        optInButtonEntity.id,
        confirmedCheckboxesIds,
        recaptchaToken,
      )

      if (
        optInButtonEntity.options.redirectionType ===
          redirectionTypes.customUrl &&
        optInButtonEntity.options.urlRedirect
      ) {
        redirectTo(optInButtonEntity.options.urlRedirect)
      } else if (
        optInButtonEntity.options.redirectionType ===
          redirectionTypes.nextStep &&
        data.redirect
      ) {
        redirectTo(data.redirect)
      }

      dispatch(optInSuccess())
      if (popup) {
        closePopup()
      }
      window.parent.postMessage(
        {
          type: `funnel_step_${funnelStepId}_form_submit_success`,
        },
        '*',
      )
    } catch (error) {
      if (error instanceof exceptions.BadRequest) {
        setErrors(error.response.data.errors.common)
        dispatch(optInFail({ fields: error.response.data.errors.fields }))
        window.dispatchEvent(new Event('form_updated'))
      } else if (error instanceof exceptions.NetworkError) {
        setErrors([t('core.errors.no_connection')])
      } else {
        Rollbar.error(`Opt-in error`, error)
      }
    }
    setLoading(false)
  }

  return [isLoading, errors, submit]
}

export default useOptInSubmit
