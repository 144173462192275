import PropTypes from 'prop-types'
import { Provider as RollbarProvider } from '@rollbar/react'
import React, { createContext } from 'react'
import { Provider } from 'react-redux'
import Page from 'publisher/components/Page'

const isSsr = typeof window === 'undefined'

export const CreateEntitiesContext = createContext(null)
export const rollbarConfig = pageId => ({
  enabled: process.env.NODE_ENV !== 'development',
  accessToken: process.env.ROLLBAR_PUBLISHER_KEY,
  captureUncaught: false,
  captureUnhandledRejections: false,
  autoInstrument: !isSsr,
  payload: {
    host: process.env.SYSTEME_IO_HOST,
    environment: process.env.APP_ENV,
    context: pageId,
  },
})

const App = ({ store, createEntityElement, createPage }) => {
  return (
    <CreateEntitiesContext.Provider value={createEntityElement}>
      <RollbarProvider config={rollbarConfig(store.getState().page.id)}>
        <Provider store={store}>
          <Page
            createEntityElement={createEntityElement}
            createPage={createPage}
          />
        </Provider>
      </RollbarProvider>
    </CreateEntitiesContext.Provider>
  )
}

App.propTypes = {
  store: PropTypes.objectOf(PropTypes.any).isRequired,
  createPage: PropTypes.func.isRequired,
  createEntityElement: PropTypes.func.isRequired,
}

export default App
