import React, { useContext } from 'react'
import {
  BaseEntityInterface,
  EntityInnerItemInterface,
} from 'common/types/entities/EntityInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { usePage } from 'publisher/store'
import {
  getChildrenEntities,
  getPageType,
} from 'publisher/store/page/pageSelectors'
import { CreateEntitiesContext } from '../app'

interface ChildrenEntitiesProps {
  childIds: string[]
}

function ChildrenEntities({ childIds }: ChildrenEntitiesProps) {
  const createEntityElement = useContext(CreateEntitiesContext) as unknown as (
    entity: BaseEntityInterface | OldEntityInterface | EntityInnerItemInterface,
    additionalProps: Record<string, any>,
    pageType: string,
  ) => JSX.Element
  const entities = usePage(page => getChildrenEntities(page, childIds))
  const pageType = usePage(getPageType)

  return (
    <>
      {entities.map(entity =>
        createEntityElement(
          entity as unknown as
            | BaseEntityInterface
            | OldEntityInterface
            | EntityInnerItemInterface,
          {},
          pageType,
        ),
      )}
    </>
  )
}

export default ChildrenEntities
